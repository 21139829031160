// menu

.category-nemu {
  // background-color: #fff;
  padding: 10px 0;

  .catgnemu-list {
    display: flex;
    align-items: center;

    li {
      position: relative;

      &:last-child:after {
        content: none;
      }

      &:first-child a {
        padding-left: 0px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 7px;
        right: -5px;
        background-image: url(/images/categories/menu-arrow.svg);
        background-repeat: no-repeat;
        width: 9px;
        height: 9px;
        background-size: contain;
      }

      a {
        color: #acacac;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        display: inline-block;
        letter-spacing: 1%;
        font-family: 'robotoregular';
        padding: 0px 12px;
        text-transform: capitalize;
      }

      &.active a {
        color: #515151;
      }

      a:hover {
        color: #E27A34;
      }
    }
  }
}

@media (max-width: 767px) {
  .category-nemu {
    background-color: #f5f5f5;

    .catgnemu-list {
      display: block;
      white-space: nowrap;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        display: inline-block;

        a {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
}